import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { CartComponent } from '@src/app/components/cart/cart.component'
import { CartItemComponentModule } from '@src/app/components/cart/item/cart-item.component.module'
import { CartPriceComponentModule } from '@src/app/components/cart/price/cart-price.component.module'
import { SharedModule } from '@src/app/shared/shared.module'
import { OuraBundleCartItemComponent } from './oura-bundle-cart-item/oura-bundle-cart-item.component'

@NgModule({
  declarations: [CartComponent],
  imports: [
    CommonModule,
    SharedModule,
    CartItemComponentModule,
    CartPriceComponentModule,
    OuraBundleCartItemComponent,
  ],
  exports: [CartComponent],
})
export class CartComponentModule {}

<div class="cartItem">
  <div class="cartItem__icon">
    <img [src]="'/assets/img/' + icon + '.svg'" />
  </div>
  <div class="cartItem__content">
    <div class="cartItem__title">
      {{ item.title }}
    </div>
  </div>
  <div class="cartItem__ouraBundlePrice">
    @if (item.price?.discount) {
      <div class="cartItem__priceDiscountOuraBundle">
        {{ item.price | discount: 0 | localize }}
      </div>
    }
    <div class="cartItem__priceOriginalOuraBundle">
      {{ item.price | localize }}
    </div>
  </div>
</div>

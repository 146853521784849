<div class="cart">
  @for (item of items; track item) {
    @if (ouraBundleInCart) {
      <app-oura-bundle-cart-item iconColor="purple" [item]="item" />
    } @else {
      <app-cart-item
        iconColor="purple"
        [item]="item"
        [nextRenewalProduct]="
          item.key === ProductKey.ROLLING_TRIAL || item.key === ProductKey.ROLLING_TRIAL_7DAYS
            ? year
            : undefined
        "
        [titleModifier]="titleModifier"
      />
    }
  }

  <div class="cart__divider"></div>

  <app-cart-price [cart]="cart" />
</div>

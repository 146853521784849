import { Component, Input, OnInit } from '@angular/core'
import { _sortBy } from '@naturalcycles/js-lib'
import {
  CartItem,
  CartState,
  HardwareId,
  Product,
  ProductKey,
  ProductVariant,
} from '@naturalcycles/shared'
import { getSignalStore } from '@src/app/core/store/signalStore'

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {
  @Input()
  protected cart!: CartState

  @Input()
  protected iconColor: 'default' | 'dark' = 'default'

  protected ProductKey = ProductKey
  protected ouraBundleInCart = false

  ngOnInit(): void {
    this.ouraBundleInCart = this.cart?.items.some(i => i.key === ProductKey.OURA_RING)
  }

  public get items(): CartItem[] {
    const items = this.cart?.items.filter(o => {
      // This will hide the Apple Watch but not the Oura Ring
      if (
        o.variants.includes(ProductVariant.thirdPartyDevice) &&
        !o.variants.includes(ProductVariant.visibleInCartSummary)
      ) {
        return false
      }
      if (o.variant === ProductVariant.ownsDevice) return false

      if (o.key === ProductKey.OURA_SHIPPING) return false

      return true
    })
    const sorted = items.sort(a => (a.key === ProductKey.OURA_RING ? 1 : -1))
    return _sortBy(sorted, item => item.type).reverse()
  }

  public get year(): Product | undefined {
    return getSignalStore()
      .$product()
      .items.find(i => i.key === ProductKey.YEAR)
  }

  public get titleModifier(): string | undefined {
    // Currently only for oura rolling trial
    return this.cart.hwId === HardwareId.OURA &&
      this.cart.items.some(i => i.key === ProductKey.ROLLING_TRIAL)
      ? 'cart-exclusiveOfferForOura'
      : undefined
  }
}

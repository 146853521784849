import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import { CartItem, ProductKey } from '@naturalcycles/shared'
import { SharedModule } from '@src/app/shared/shared.module'

@Component({
  standalone: true,
  selector: 'app-oura-bundle-cart-item',
  templateUrl: './oura-bundle-cart-item.component.html',
  styleUrls: ['./oura-bundle-cart-item.component.scss'],
  imports: [CommonModule, SharedModule],
})
export class OuraBundleCartItemComponent {
  @Input()
  protected item!: CartItem

  @Input()
  protected iconColor: 'default' | 'dark' = 'default'

  @Input()
  protected ouraBundleInCart = false

  protected ProductKey = ProductKey

  public get icon(): string {
    return this.iconColor === 'default' ? 'check-prosecco' : 'check'
  }
}
